export class FilesHelpers {

    constructor() { }

    public static save(content: any, fileName: string): void {

        const blob = new Blob([content], { type: 'application/octet-stream' });

        const link = document.createElement('a');

        link.style.display = 'none';
        link.href = window.URL.createObjectURL(blob);
        link.download = fileName;
        link.click();
        link.remove();
    }

    public static loadAsArrayBuffer(accept: string = ''): Promise<string | ArrayBuffer> {

        return FilesHelpers.load('readAsArrayBuffer', accept);
    }

    public static loadAsBinaryString(accept: string = ''): Promise<string | ArrayBuffer> {

        return FilesHelpers.load('readAsBinaryString', accept);
    }

    public static loadAsDataURL(accept: string = ''): Promise<string | ArrayBuffer> {

        return FilesHelpers.load('readAsDataURL', accept);
    }

    public static loadAsText(accept: string = ''): Promise<string | ArrayBuffer> {

        return FilesHelpers.load('readAsText', accept);
    }

    private static load(method: 'readAsText' | 'readAsDataURL' | 'readAsBinaryString' | 'readAsArrayBuffer', accept: string = ''): Promise<string | ArrayBuffer> {

        const input = document.createElement('input');

        input.style.display = 'none';
        input.type = 'file';
        input.accept = accept;
        input.click();

        return new Promise(resolve => {

            input.oninput = () => {

                if (!input.files?.length) {
                    return;
                }

                const fileReader = new FileReader();

                fileReader.onload = _ => {
                    resolve(fileReader.result || '');
                }

                (fileReader)[method](input.files[0]);
            };
        });
    }
}
