import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CancelationComponent } from './components/cancelation/cancelation.component';
import { FormsModule } from '@angular/forms';

@NgModule({
    declarations: [
        CancelationComponent
    ],
    imports: [
        CommonModule,
        FormsModule
    ]
})

export class CancelationModule { }
