import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LandingComponent } from './components/landing/landing.component';
import { RouterModule } from '@angular/router';
import { SharedModule } from "../shared/shared.module";
@NgModule({
    declarations: [
        LandingComponent
    ],
    imports: [
    CommonModule,
    RouterModule,
    SharedModule
]
})

export class LandingModule { }
