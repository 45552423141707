
<section>
    <header>Notation Markdown</header>

    La notation Markdown vous permet de structurer la description de vos événements dans Kireidy.<br/>
    Retrouvez des exemples et expérimentez cette notation dès maintenant:
    
</section>

<textarea class="input" [(ngModel)]="text" autoHeight></textarea>

<div class="output" [innerHtml]="text | markdown"></div>