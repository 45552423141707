export class Member {

    public id = '';

    public key = '';

    public pseudo = '';

    public group = '';

    public email = '';

    public firstName?: string;

    public lastName?: string;

    public phone?: string;

    public dob?: Date;

}
