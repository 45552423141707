import { Member } from './member.entity';

export class XEvent {

    public id?: any;

    public name = '';

    public description = '';

    public organisator = '';

    public location = '';

    public imagePosition = '';

    public date = new Date(Date.now() + 10000);

    public limit = 0;

    public members = new Array<Member>();

    public email = '';

    public key = '';

    public isSimplifiedInscription = false;

    public isTimedReading = false;

}

export class XEventImage {

    public eventId!: string;

    public data!: Buffer;

    public type!: string;
}
