import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MarkdownComponent } from './components/markdown/markdown.component';
import { SharedModule } from '../shared/shared.module';

@NgModule({
    declarations: [
        MarkdownComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        SharedModule
    ],
    exports: [
        MarkdownComponent
    ]
})

export class MarkdownModule { }
