import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { CreateXEventRequest, CreateXEventResponse, XEvent, LeaveXEventRequest, LeaveXEventResponse, Member, UpdateXEventRequest, DeleteXEventRequest, DeleteXEventResponse } from '@kireidy/definitions';
import { environment } from '../../../../environments/environment';
import { OnlineService } from '../../../online/services/online.service';
import { EmailRegExp } from '../../../shared/helpers/emails/emails.helpers';
import { FilesHelpers } from '../../../shared/helpers/files/files.helpers';

import { OverlayService } from '../../../shared/services/overlay/overlay.service';
import { TranslateService } from '@ngx-translate/core';
import { ModalConfirmComponent } from '../../../shared/components/modal-confirm/modal-confirm.component';

@Component({
    selector: 'kireidy-edition',
    templateUrl: './edition.component.html',
    styleUrls: ['./edition.component.scss']
})

export class EditionComponent implements OnInit {

    public isLoading = false;
    public isUrlCopied = false;
    public isPreviewingDescription = false;
    public invalidity: string | null = null;

    public event = new XEvent();

    private newImage: string | undefined;

    constructor(
        private title: Title,
        private meta: Meta,
        private route: ActivatedRoute,
        private router: Router,
        private translateService: TranslateService,
        private overlayService: OverlayService,
        private onlineService: OnlineService) { }

    ngOnInit(): void {

        const eventId = this.route.snapshot.paramMap.get('eventId');

        if (!eventId) {

            this.title.setTitle('Creation'); // TRANSLATION ;)

            this.checkValidity();

            return;
        }

        this.title.setTitle('Edition');

        this.onlineService
            .getEvent(eventId)
            .pipe(
                finalize(() => {
                    this.isLoading = false;
                })
            )
            .subscribe({
                next: (response) => {

                    this.event = response;

                    this.title.setTitle(this.event.name);

                    // For old events without these properties
                    this.event.isSimplifiedInscription = this.event.isSimplifiedInscription !== false;
                    this.event.isTimedReading = this.event.isTimedReading === true;

                    this.checkValidity();
                },
                error: () => {

                    this.overlayService.openNotification({
                        message: this.translateService.instant('event.notFound'),
                        type: 'error'
                    });

                    this.router.navigateByUrl('/');
                }
            });
    }

    public get isIncomplete(): boolean {

        return !this.event.name ||
            !this.event.location ||
            (this.event.date.getTime() < Date.now()) ||
            !this.event.email;
    }

    public checkValidity(): void {

        this.invalidity = '';

        if (!this.event.name) {
            this.invalidity = this.translateService.instant('edition.invalidities.missingName');

            return;
        }

        if (!this.event.organisator) {
            this.invalidity = this.translateService.instant('edition.invalidities.missingOrganisator');

            return;
        }

        if (!this.event.location) {
            this.invalidity = this.translateService.instant('edition.invalidities.missingLocation');

            return;
        }

        if (this.event.date.getTime() < Date.now()) {
            this.invalidity = this.translateService.instant('edition.invalidities.outdated');

            return;
        }

        if (!EmailRegExp.test(this.event.email)) {
            this.invalidity = this.translateService.instant('edition.invalidities.invalidCreatorEmail');

            return;
        }
    }

    public get image(): any {

        return this.newImage || (this.event.id ? `${this.onlineService.backendUrl}/events/${this.event.id}/image` : ``);
    }

    public onDateChange(event: string): void {

        const fields: Array<number> = event.split('-').map(field => parseInt(field, 10));

        this.event.date.setFullYear(fields[0], fields[1] - 1, fields[2]);
    }

    public onTimeChange(event: string): void {

        const fields: Array<number> = event.split(':').map(field => parseInt(field, 10));

        this.event.date.setHours(fields[0], fields[1]);
    }

    public async onImageClick(): Promise<void> {

        const file = await FilesHelpers.loadAsDataURL('image/*');

        const image:  HTMLImageElement = document.createElement('img');

        image.onload = () => {

            const ratios = {
                with: image.width / environment.images.maximumSize,
                height: image.height / environment.images.maximumSize,
                biggest: 0
            };

            ratios.biggest = (ratios.with > ratios.height ? ratios.with : ratios.height);

            console.log(ratios.biggest);

            if (ratios.biggest <= 1) {

                // Return keeping the image like it is
                return;
            }

            const canvas: HTMLCanvasElement = document.createElement('canvas');

            canvas.width = image.width / ratios.biggest;
            canvas.height = image.height / ratios.biggest;

            const context = canvas.getContext('2d');

            if (!context) {

                return;
            }

            context.drawImage(image, 0, 0, canvas.width, canvas.height);

            this.newImage = canvas.toDataURL('image/*') as any;
        }

        image.src = file as any;
    }

    public onImagePosition(position: string): void {

        this.event.imagePosition = position;
    }

    public async onDeleteMember(member: Member): Promise<void> {

        const key: string = this.route.snapshot.paramMap.get('key') || '';

        if (!this.event.id || !key) {
            return;
        }

        if (await this.overlayService.openModal({
            component: ModalConfirmComponent,
            inputs: {
                description: this.translateService.instant('edition.confirmations.delete.member')
            }
        }) === false) {

            return;
        }

        const request: LeaveXEventRequest = {
            eventId: this.event.id  || '',
            memberId: member.id,
            key
        };

        this.isLoading = true;

        this.onlineService
            .leaveXEvent(request)
            .pipe(finalize(() => { this.isLoading = false; }))
            .subscribe({
                next: (response: LeaveXEventResponse) => {

                    this.event.members = response.members;
                },
                error: (response) => {
                    console.error(response.error);
                }
            });

    }

    public onCopyMember(member: Member): void {

        // Google sheets separators betweek fields
        navigator.clipboard.writeText(`${member.pseudo}	${member.group}	${member.email}	${member.firstName}	${member.lastName}	${member.phone}	${member.dob}`);

        this.overlayService.openNotification({
            message: this.translateService.instant('edition.notifications.memberCopied'),
            type: 'success'
        });
    }

    public async onDelete(): Promise<void> {

        const key: string = this.route.snapshot.paramMap.get('key') || '';

        if (!this.event.id || !key) {
            return;
        }

        if (await this.overlayService.openModal({
            component: ModalConfirmComponent,
            inputs: {
                description: this.translateService.instant('edition.confirmations.delete.event')
            }
        }) === false) {

            return;
        }

        this.isLoading = true;

        this.onlineService
            .deleteXEvent(this.event.id, { key })
            .pipe(finalize(() => { this.isLoading = false; }))
            .subscribe({
                next: (response: DeleteXEventResponse) => {

                    this.overlayService.openNotification({
                        message: this.translateService.instant('edition.notifications.delete.success'),
                        type: 'error' //
                    });

                    this.router.navigateByUrl('/');
                },
                error: (response) => {

                    console.error(response.error);

                    this.overlayService.openNotification({
                        message: this.translateService.instant('edition.notifications.delete.error'),
                        type: 'error'
                    });
                }
            });
    }

    public onView(): void {

        window.open(`/events/${this.event.id}?skip=true`);
    }

    public onShare(): void {

        navigator.clipboard.writeText(`${window.location.origin}/events/${this.event.id}${this.isUrlCopied ? '?skip=true' : ''}`);

        this.overlayService.openNotification({
            message: this.translateService.instant('edition.notifications.urlCopied'),
            type: 'success'
        });

        this.isUrlCopied = true;

        setTimeout(() => { this.isUrlCopied = false; }, 3000);
    }

    public onSave(): void {

        if(this.event.id) {

            this.update();

            return;
        }

        this.create();
    }

    private create(): void {

        // Create new
        const { id, members, key, ...required } = this.event;

        const createEventRequest: CreateXEventRequest = {
            ...required,
            image: this.newImage
        };

        this.isLoading = true;

        this.onlineService.createXEvent(createEventRequest)
            .pipe(finalize(() => { this.isLoading = false; }))
            .subscribe({
                next: (response: CreateXEventResponse) => {

                    const { key, ...eventData } = response;

                    this.event = Object.assign(this.event, eventData);

                    this.title.setTitle(this.event.name);
                    this.newImage = undefined;

                    this.overlayService.openNotification({
                        message: this.translateService.instant('edition.notifications.creation.success'),
                        type: 'success'
                    });

                    this.router.navigate([`/events/${this.event.id}/edition/${key}`]);
                },
                error: (response) => {

                    console.error(response.error);

                    this.overlayService.openNotification({
                        message: this.translateService.instant('edition.notifications.creation.error'),
                        type: 'error'
                    });
                }
            });
    }

    private update(): void {

        const key: string = this.route.snapshot.paramMap.get('key') || '';

        // Don't update members list, useless and probably out to date
        const { id, members, ...data } = this.event;

        // Update existing
        const updateEventRequest: UpdateXEventRequest = {
            ...data,
            key,
            image: this.newImage
        };

        this.isLoading = true;

        this.onlineService
            .updateXEvent(id, updateEventRequest)
            .pipe(finalize(() => { this.isLoading = false; })
            )
            .subscribe({
                next: (response: boolean) => {

                    console.log('updated', response);

                    this.title.setTitle(this.event.name);
                    this.newImage = undefined;

                    this.overlayService.openNotification({
                        message: this.translateService.instant('edition.notifications.save.success'),
                        type: 'success'
                    });
                },
                error: (response) => {

                    console.error(response.error);

                    this.overlayService.openNotification({
                        message: this.translateService.instant('edition.notifications.save.error'),
                        type: 'error'
                    });
                }
            });

    }

}
