import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { Router } from '@angular/router';

@Component({
    selector: 'kireidy-landing',
    templateUrl: './landing.component.html',
    styleUrls: ['./landing.component.scss']
})
export class LandingComponent implements OnInit {

    constructor(        
        private title: Title, 
        private meta: Meta,
        private router: Router) { }

    ngOnInit(): void {

        this.title.setTitle('Kireidy');
    }

    public onStart(): void {

        this.router.navigate(['/events/create']);
    }

}
