import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
    selector: 'kireidy-markdown',
    templateUrl: './markdown.component.html',
    styleUrls: ['./markdown.component.scss']
})

export class MarkdownComponent {

    public text: string = 
        '# Mon évènement\n' +
        'Vous êtes invités à mon évènement élaboré.\n' +
        '\n' +
        '## Prérequis\n' +
        'Veuillez vous munir de:\n' +
        '- Votre maillot de bain.\n' +
        '- De crème solaire.\n' +
        '- Une grande serviette.\n' +
        '\n' +
        '## Programme\n' +
        'Après installation sur la plage, farniente.\n' +
        '\n' +
        '![Plage](https://upload.wikimedia.org/wikipedia/commons/thumb/d/d9/Colombus_Isle.JPG/220px-Colombus_Isle.JPG)\n' +
        '\n' +
        '# Titre 1\n' +
        '## Titre 2\n' +
        '### Titre 3\n' +
        'Texte normal\n' +
        '- Puce 1\n' +
        '- Puce 2\n' +
        '- Puce 3\n' +
        '1. Puce numérotée 1\n' +
        '2. Puce numérotée 2\n' +
        '3. Puce numérotée 3\n' +
        '\n' +
        '[lien](http://www.google.com)\n' +
        '\n' +
        '![Image](https://www.google.com/images/branding/googlelogo/1x/googlelogo_color_272x92dp.png)\n';

        
    constructor(private title: Title) { 

        this.title.setTitle('Markdown');
    }

}
