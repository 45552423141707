<div class="description">
    <div class="title">
        <ng-content select="[title]"></ng-content>
    </div>
    <div class="detail">
        <ng-content select="[detail]"></ng-content>
    </div>
</div>

<div class="value">
    <ng-content select="[value]"></ng-content>
</div>
