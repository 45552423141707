export class App {

    // The name of the app.
    public name!: string;

    // To specify if the app is not directly in a directory named like it at the root of 'dist'
    public path?: string;

    // To specify if the app has a sub domain, true by default but must be false with the the main frontend.
    public hasSubdomain?: boolean = true;

    // To specify if the app had to be served, true by default but must be false with backends.
    public isStatic?: boolean = true;

    // The port used to join the port locally, used by the static server and the resolveAppUrl() method when you work locally.
    public localPort!: number;
}
