import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CancelationComponent } from './cancelation/components/cancelation/cancelation.component';
import { EditionComponent } from './edition/components/edition/edition.component';
import { InscriptionComponent } from './inscription/components/inscription/inscription.component';
import { LandingComponent } from './landing/components/landing/landing.component';
import { MarkdownComponent } from './markdown/components/markdown/markdown.component';

const routes: Routes = [
    { path: '',                                                 component: LandingComponent },

    { path: 'events/create',                                    component: EditionComponent },
    { path: 'events/:eventId',                                  component: InscriptionComponent },
    { path: 'events/:eventId/edition/:key',                     component: EditionComponent },
    { path: 'events/:eventId/cancelation/:memberId/:key',       component: CancelationComponent },
    { path: 'markdown',                                         component: MarkdownComponent },

    { path: '**',                                               component: LandingComponent }, // Not found
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {})],
    exports: [RouterModule]
})

export class AppRoutingModule { }
