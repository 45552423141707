/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-empty-function */

/**
 * The middleware populates the location property which is normally available only in a browser
 * Do do it, it uses the informations from the first request made through express (hostname...)
 * @returns next
 */
 export function locate() {
    return (req: any, res: any, next: any) => {

        if (!globalThis.location) {

            const {
                hash,
                hostname,
                href,
                origin,
                pathname,
                port,
                protocol,
                search
            } = req;

            globalThis.location = {
                ancestorOrigins: {
                    length: 0,
                    contains: () => false,
                    item: () => null
                },
                hash,
                host: '',                                                       // Deprecated
                hostname: req.headers['x-forwarded-host'] || hostname,          // If forwarded by a reverse proxy (eg nginx) use the forwarded host, not the local one
                href,
                origin,
                pathname,
                port,
                protocol: (req.headers['x-forwarded-proto'] || protocol) + ':', // If forwarded by a reverse proxy (eg nginx) use the forwarded protocol, not the local one
                search,
                assign: () => {},
                reload:() => {},
                replace: () => {}
            };
        }

        next();
    }
}
