import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { InscriptionComponent } from './components/inscription/inscription.component';
import { SharedModule } from '../shared/shared.module';
import { InscriptionModalComponent } from './components/inscription-modal/inscription-modal.component';

@NgModule({
    declarations: [
        InscriptionComponent,
        InscriptionModalComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        SharedModule
    ]
})

export class InscriptionModule { }
