import { Component, HostListener, Input, OnInit } from '@angular/core';
import { OverlayService } from '../../../shared/services/overlay/overlay.service';
import { JoinXEventRequest, XEvent } from '@kireidy/definitions';
import { EmailRegExp } from '../../../shared/helpers/emails/emails.helpers';
import { OnlineService } from '../../../online/services/online.service';
import { finalize } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'kireidy-inscription-modal',
    templateUrl: './inscription-modal.component.html',
    styleUrls: ['./inscription-modal.component.scss'],
})
export class InscriptionModalComponent implements OnInit {

    @Input()
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    public resolve = (value: boolean | null = null) => { /* */ };

    @Input()
    public event: XEvent = new XEvent;

    public request = new JoinXEventRequest();

    public emailConfirmation = '';

    public invalidity = '';

    public isLoading = false;

    constructor(
        private translateService: TranslateService,
        private overlayService: OverlayService,
        private onlineService: OnlineService) { }

    public ngOnInit(): void {

        this.request.eventId = this.event.id;

        this.checkValidity();
    }

    @HostListener('document:keyup.escape', ['$event'])
    public cancel(): void {

        this.resolve(false);

        this.overlayService.closeModal();
    }

    @HostListener('document:keyup.enter', ['$event'])
    public confirm(): void {

        this.isLoading = true;

        this.onlineService
            .joinEvent(this.request)
            .pipe(finalize(() => { this.isLoading = false; })
            )
            .subscribe({
                next: (response) => {

                    // Update the members list (reference as input)
                    this.event.members = response.members;

                    this.overlayService.openNotification({
                        message: this.translateService.instant('inscription.notifications.success'),
                        type: 'success'
                    });

                    this.resolve(true);

                    this.overlayService.closeModal();
                },
                error: (response) => {
                    console.error(response.error);

                    this.overlayService.openNotification({
                        message: this.translateService.instant('inscription.notifications.error', { message: response.error.message }),
                        type: 'error'
                    });
                }
            });
    }

    public checkValidity() {

        this.invalidity = '';

        if (!this.request.pseudo.trim()) {
            this.invalidity = this.translateService.instant('inscription.invalidities.missingPseudo');

            return;
        }

        if (this.event.members.some(member => member.pseudo.toUpperCase() === this.request.pseudo.trim().toUpperCase())) {
            this.invalidity = this.translateService.instant('inscription.invalidities.unavailablePseudo');

            return;
        }

        if (!this.event.isSimplifiedInscription) {

            if (!this.request.firstName?.trim()) {
                this.invalidity = this.translateService.instant('inscription.invalidities.missingFirstName');

                return;
            }

            if (!this.request.lastName?.trim()) {
                this.invalidity = this.translateService.instant('inscription.invalidities.missingLastName');

                return;

            }

            if (this.request.phone?.trim().length !== 10) {    // USE A REGEXP
                this.invalidity = this.translateService.instant('inscription.invalidities.missingPhone');

                return;

            }

            if (!this.request.dob) {
                this.invalidity = this.translateService.instant('inscription.invalidities.missingDob');

                return;
            }

            const   dateEighteenAgo = new Date();
                    dateEighteenAgo.setFullYear(new Date().getFullYear() - 18);

            if (new Date(this.request.dob).getTime() > dateEighteenAgo.getTime()) {
                this.invalidity = this.translateService.instant('inscription.invalidities.invalidDob');

                return;
            }
        }

        if (!this.request.email) {
            this.invalidity = this.translateService.instant('inscription.invalidities.missingEmail');

            return;
        }

        if (!EmailRegExp.test(this.request.email)) {
            this.invalidity = this.translateService.instant('inscription.invalidities.invalidEmail');

            return;
        }

        if (this.request.email !== this.emailConfirmation) {
            this.invalidity = this.translateService.instant('inscription.invalidities.mismatchingEmail');

            return;
        }

    }
}
