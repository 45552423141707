
<kireidy-entry class="name">
    <div title>{{ 'edition.name.title' | translate }} *</div>
    <div detail>{{ 'edition.name.detail' | translate }}</div>
    <input
        value
        type="text"
        [(ngModel)]="event.name"
        (input)="checkValidity()">
</kireidy-entry>

<kireidy-entry class="limit">
    <div title>{{ 'edition.limit.title' | translate }} *</div>
    <div detail>{{ 'edition.limit.detail' | translate }}</div>
    <input
        value
        type="number"
        min="0"
        title="limite"
        [(ngModel)]="event.limit">
</kireidy-entry>

<kireidy-entry class="organisator">
    <div title>{{ 'edition.organisators.title' | translate }} *</div>
    <div detail>{{ 'edition.organisators.detail' | translate }}</div>
    <input
        value
        type="text"
        [(ngModel)]="event.organisator"
        (input)="checkValidity()">
</kireidy-entry>

<kireidy-entry class="date">
    <div title>{{ 'edition.date.title' | translate }} *</div>
    <div detail>{{ 'edition.date.detail' | translate }}</div>
    <input
        value
        type="date"
        [ngModel] ="event.date | date:'yyyy-MM-dd'"
        (ngModelChange)="onDateChange($event)"
        (input)="checkValidity()">
</kireidy-entry>

<kireidy-entry class="time">
    <div title>{{ 'edition.time.title' | translate }} *</div>
    <div detail>{{ 'edition.time.detail' | translate }}</div>
    <input
        value
        type="time"
        [ngModel] ="event.date | date:'hh:mm'"
        (ngModelChange)="onTimeChange($event)"
        (input)="checkValidity()">
</kireidy-entry>

<kireidy-entry class="location">
    <div title>{{ 'edition.location.title' | translate }} *</div>
    <div detail>{{ 'edition.location.detail' | translate }}</div>
    <input
        value
        type="text"
        [(ngModel)]="event.location"
        (input)="checkValidity()">
</kireidy-entry>

<kireidy-entry class="image">
    <div title>{{ 'edition.image.title' | translate }} *</div>
    <div detail>{{ 'edition.image.detail' | translate }}</div>
    <div
        value
        class="content"
        (click)="onImageClick()"
        [style.backgroundImage]="'url(' + image + ')'"
        [style.backgroundPosition]="event.imagePosition">

        <div class="position">
            <div class="lt" (click)="onImagePosition('left top');       $event.stopPropagation()"></div>
            <div class="ct" (click)="onImagePosition('center top');     $event.stopPropagation()"></div>
            <div class="rt" (click)="onImagePosition('right top');      $event.stopPropagation()"></div>
            <div class="lc" (click)="onImagePosition('left center');    $event.stopPropagation()"></div>
            <div class="cc" (click)="onImagePosition('center center');  $event.stopPropagation()"></div>
            <div class="rc" (click)="onImagePosition('right center');   $event.stopPropagation()"></div>
            <div class="lb" (click)="onImagePosition('left bottom');    $event.stopPropagation()"></div>
            <div class="cb" (click)="onImagePosition('center bottom');  $event.stopPropagation()"></div>
            <div class="rb" (click)="onImagePosition('right bottom');   $event.stopPropagation()"></div>
        </div>
    </div>
</kireidy-entry>

<kireidy-entry class="description">
    <div title>{{ 'edition.description.title' | translate }} *</div>
    <div detail>
        <span>{{ 'shared.preview' | translate }}</span>
        <input type="checkbox" [(ngModel)]="isPreviewingDescription">
    </div>
    <ng-container value>
        <textarea
            [(ngModel)]="event.description">
        </textarea>

        @if(isPreviewingDescription) {
            <div class="preview"
                [innerHtml]="event.description | markdown">
            </div>
        }
    </ng-container>
</kireidy-entry>

@if (event.members.length > 0) {
    <kireidy-entry class="members">
        <div title>{{ 'edition.members.title' | translate }} *</div>
        <div detail>{{ 'edition.members.detail' | translate }}</div>
        <div value class="content">
        @for (member of event.members; track member; let index = $index) {
            <div class="member">

                <div class="number">
                    {{ index + 1 | number:'2.0' }}
                </div>

                <div class="information" (click)="onCopyMember(member)">

                    <div class="main">
                        {{ member.pseudo }}

                        @if(member.group) {
                            ({{ member.group }})
                        }
                    </div>

                    <div class="details">
                        <div>{{ member.email }}</div>
                        <div>{{ member.firstName }}</div>
                        <div>{{ member.lastName }}</div>
                        <div>{{ member.phone }}</div>
                        <div>{{ member.dob | date: 'dd/MM/yyyy' }}</div>
                    </div>

                </div>

                <kireidy-icon-button class="remove" (click)="onDeleteMember(member)">🗙</kireidy-icon-button>
            </div>
        }
        </div>
    </kireidy-entry>
}

<kireidy-entry class="email">
    <div title>{{ 'edition.creatorEmail.title' | translate }} *</div>
    <div detail>{{ 'edition.creatorEmail.detail' | translate }}</div>
    <input
        value
        type="email"
        name="email"
        [(ngModel)]="event.email"
        (input)="checkValidity()"/>
</kireidy-entry>

<div class="options">

    <kireidy-entry>
        <div title>{{ 'edition.options.simplifiedInscription.title' | translate }}</div>
        <div detail>{{ ('edition.options.simplifiedInscription.detail.' + (event.isSimplifiedInscription ? 'yes' : 'no')) | translate }}</div>
        <select
            value
            [(ngModel)]="event.isSimplifiedInscription">
            <option [ngValue]="true">{{ 'shared.yes' | translate }}</option>
            <option [ngValue]="false">{{ 'shared.no' | translate }}</option>
        </select>
    </kireidy-entry>

    <kireidy-entry>
        <div title>{{ 'edition.options.timedReading.title' | translate }}</div>
        <div detail>{{ ('edition.options.timedReading.detail.' + (event.isTimedReading ? 'yes' : 'no')) | translate }}</div>
        <select
            value
            [(ngModel)]="event.isTimedReading">
            <option [ngValue]="true">{{ 'shared.yes' | translate }}</option>
            <option [ngValue]="false">{{ 'shared.no' | translate }}</option>
        </select>
    </kireidy-entry>

</div>


@if (invalidity) {
    <div class="invalidity">{{ invalidity }}</div>
}

<div class="buttons">

    <kireidy-icon-button
        (click)="onDelete()"
        [class.disabled]="!event.id || isLoading"
        [title]="'edition.buttons.delete' | translate">
        🗙
    </kireidy-icon-button>

    <kireidy-icon-button
        (click)="onSave()"
        [class.disabled]="isLoading || isIncomplete"
        [class.pulsing-fast]="isLoading"
        [title]="'edition.buttons.save' | translate">
        💾
    </kireidy-icon-button>

    <kireidy-icon-button
        (click)="onView()"
        [class.disabled]="!event.id || isLoading"
        [title]="'edition.buttons.view' | translate">
        👓
    </kireidy-icon-button>

    <kireidy-icon-button
        (click)="onShare()"
        [class.disabled]="!event.id"
        [title]="'edition.buttons.link' | translate">
        🔗
    </kireidy-icon-button>

</div>
