/* eslint-disable @typescript-eslint/no-inferrable-types */
export class PaginedRequest {

    // @Expose()
    // @IsNumber()
    // @Type(() => Number) // DTOs became serialized strings when they are a Query of a Get request, class transformer had to convert it back.
    public index = 0;

    // @Expose()
    // @IsNumber()
    // @Type(() => Number)
    public limit = 0;

    /* extends this type with some filtering and sorting properties */
}

// Deprecated: Create dtos inheriting the following class
export class PaginedResponse {

    public index: number = 0;

    public total: number = 0;

    /* extends this type with items: Array<THE_ITEM_TYPE>; */
}

// Better way: Directly use PaginedResponseT<THE_ITEM_TYPE>, may crash the swagger plugin :s
export class PaginedResponseT<T> {

    public index: number = 0;

    public total: number = 0;

    public items: Array<T> = [];
}

