import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
    XEvent,
    CreateXEventRequest,
    UpdateXEventRequest,
    CreateXEventResponse,
    JoinXEventRequest,
    JoinXEventResponse,
    LeaveXEventRequest,
    LeaveXEventResponse,
    Host,
    DeleteXEventRequest,
    DeleteXEventResponse
} from '@kireidy/definitions';
import { AppsUtils } from '@kireidy/configuration';


@Injectable({
    providedIn: 'root'
})
export class OnlineService {

    private _backendUrl: string;

    constructor(private http: HttpClient) {

        this._backendUrl = AppsUtils.resolveAppUrl('backend');

        console.log(`OnlineService: Backend url used: ${this._backendUrl}`);
    }

    public get backendUrl(): string {
        return this._backendUrl;
    }

    public getHost(): Observable<Host> {
        return this.http.get<Host>(`${this._backendUrl}/state/host`);
    }

    public getEvent(id: string): Observable<XEvent> {

        return this.http.get<XEvent>(`${this._backendUrl}/events/${id}`);
    }

    public createXEvent(request: CreateXEventRequest): Observable<CreateXEventResponse> {

        return this.http.post<CreateXEventResponse>(`${this._backendUrl}/events`, request);
    }

    public deleteXEvent(id: string, request: DeleteXEventRequest): Observable<DeleteXEventResponse> {

        const params = new HttpParams({ fromObject: { ...request }});

        return this.http.delete<DeleteXEventResponse>(`${this._backendUrl}/events/${id}`, { params });
    }

    public updateXEvent(id: string, request: UpdateXEventRequest): Observable<boolean> {

        return this.http.patch<boolean>(`${this._backendUrl}/events/${id}`, request);
    }

    public joinEvent(request: JoinXEventRequest): Observable<JoinXEventResponse> {

        return this.http.post<JoinXEventResponse>(`${this._backendUrl}/events/${request.eventId}/join`, request);
    }

    public leaveXEvent(request: LeaveXEventRequest): Observable<LeaveXEventResponse> {

        return this.http.post<LeaveXEventResponse>(`${this._backendUrl}/events/${request.eventId}/leave`, request);
    }

}
