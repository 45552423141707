/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-unused-vars */

// ---------------------------------------------------------------------------------------------------
// Nestjs/swagger : DISABLED
// ---------------------------------------------------------------------------------------------------

export function ApiExtraModels(...args: unknown[]): PropertyDecorator { return function () {}}
export function ApiProperty(...args: unknown[]): PropertyDecorator { return function () {}}
export function Prop(...args: unknown[]): PropertyDecorator { return function () {}}
export function Schema(...args: unknown[]): ClassDecorator { return function () {}}


// ---------------------------------------------------------------------------------------------------
// Nestjs/mongoose : DISABLED
// ---------------------------------------------------------------------------------------------------

class EmptyClass {
    constructor() {}
    name(): void {}
}

export class SchemaFactory {
    static createForClass(...args: unknown[]) {
        return Object.getPrototypeOf(EmptyClass)
    };
}

// ---------------------------------------------------------------------------------------------------
// Class transformer : DISABLED
// ---------------------------------------------------------------------------------------------------

export function Transform(...args: unknown[]): PropertyDecorator { return function () {}}
export function Type(...args: unknown[]): PropertyDecorator { return function () {}}

// ---------------------------------------------------------------------------------------------------
// Class validator : DISABLED
// ---------------------------------------------------------------------------------------------------

export function ValidateNested(...args: unknown[]): PropertyDecorator { return function () {}}
export function IsNumber(...args: unknown[]): PropertyDecorator { return function () {}}
export function IsEnum(...args: unknown[]): PropertyDecorator { return function () {}}

export function IsObjectId(...args: unknown[]): PropertyDecorator { return function () {}}
