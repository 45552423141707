<ng-container *ngIf="isLoading; else loaded">
    Annulation en cours...
</ng-container>

<ng-template #loaded>

    <ng-container *ngIf="error; else done">

        Erreur: {{ error }}
    </ng-container>

    <ng-template #done>

        Annulation effectuée!
    </ng-template>

</ng-template>