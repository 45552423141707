import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { LeaveXEventRequest } from '@kireidy/definitions';
import { finalize } from 'rxjs/operators';
import { OnlineService } from '../../../online/services/online.service';

@Component({
    selector: 'kireidy-cancelation',
    templateUrl: './cancelation.component.html',
    styleUrls: ['./cancelation.component.scss']
})
export class CancelationComponent implements OnInit {

    public isLoading = false;
    public error = '';

    constructor(
        private title: Title, 
        private meta: Meta,
        private route: ActivatedRoute, 
        private onlineService: OnlineService) { }

    ngOnInit(): void {

        this.title.setTitle('Annulation');

        this.isLoading = true;

        const request: LeaveXEventRequest = {
            eventId: this.route.snapshot.paramMap.get('eventId') || '',
            memberId: this.route.snapshot.paramMap.get('memberId') || '',
            key: this.route.snapshot.paramMap.get('key') || ''
        };

        console.log(request);

        this.onlineService
            .leaveXEvent(request)
            .pipe(finalize(() => { this.isLoading = false; }))
            .subscribe({
                error: (response) => {
                    console.error(response.error);

                    this.error = response.error.message;
                }
            });
    }

}
