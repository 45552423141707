<kireidy-modal-layout>

    <!-- <div title>{{ title }}</div> -->

    <div body>{{ description }}</div>

    <div class="footer" footer>

        <kireidy-icon-button
            class="cancel"
            (click)="cancel()">
            🗙
        </kireidy-icon-button>

        <kireidy-icon-button
            class="confirm"
            (click)="confirm()">
            ✔
        </kireidy-icon-button>

    </div>

</kireidy-modal-layout>
