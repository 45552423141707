import { Component, OnInit } from '@angular/core';
import { Host } from '@kireidy/definitions';
import { OnlineService } from '../../../online/services/online.service';

@Component({
    selector: 'kireidy-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

    public year: number = (new Date()).getFullYear();

    public host?: Host;

    constructor(private onlineService: OnlineService) { }

    public ngOnInit(): void {

        this.onlineService.getHost().subscribe({
            next: (host: Host) => {
                this.host = host;
            }
        });
    }

}
