// Used by the static server and the resolveAppUrl() method.

import { App } from '../entities/app.entity';

export const apps: Array<App> = [
    {
        name: 'backend',
        localPort: 3000,
        isStatic: false
    },
    {
        name: 'frontend',
        path: 'frontend/browser',       // This app has 2 sub directories 'server' and 'browser' because also worked with SSR.
        hasSubdomain: false,            // Main frontend, no subdomain
        localPort: 4200
    },
];
